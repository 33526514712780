import React from "react";
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import { FeatureCard } from "../components/feature-card";
import { featuresData } from "../data/features-data";
import New from "../components/noticias";
import Footer from "../components/footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StatsCounter from "../components/statistics";

const Home = () => {
  const images = [
    "https://newslab.com.br/wp-content/uploads/2022/12/960x720-4-1.png",
    "https://i.blogs.es/60d664/biologiacompap/1366_2000.jpg",
    "https://newslab.com.br/wp-content/uploads/2022/12/960x720-4-1.png",
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1,
    cssEase: "linear",
    fade: true,
  };
  return (
    <>
      <Slider
        {...settings}
        className="relative flex h-10000 content-center items-center justify-center mt-3"
      >
        {images.map((image, index) => (
          <div key={index}>
            <div
              className="absolute top-0 h-full w-full bg-cover bg-center"
              style={{ backgroundImage: `url(${image})` }}
            />
            <div className="absolute top-0 h-full w-full bg-black/75" />
            <div className="max-w-8xl container relative mx-auto">
              <div className="flex flex-wrap items-center">
                <div className="ml-auto mr-auto w-full px-4 py-5 text-center lg:w-8/12">
                  <Typography
                    variant="h1"
                    color="white"
                    className="mb-6 pt-16 pb-20 font-extrabold font-sans "
                  >
                    Automatic Data-driven Analytics Laboratory
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <section className="-mt-32 bg-gray-50 px-4 pb-2 pt-4  font-sans">
        <div className="container mx-auto font-sans">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 py-5 font-sans">
            {featuresData.map(({ color, title, icon }) => (
              <FeatureCard
                className="w-10 h-10 font-extrabold font-sans"
                key={title}
                color={color}
                title={title}
                icon={React.createElement(icon, {
                  className: "w-8 h-8 text-white",
                })}
              />
            ))}
          </div>
          <div className="mt-10 flex flex-wrap items-center">
            <div className="mx-auto  w-full px-4 md:w-7/12">
              <Typography
                variant="h3"
                className="mb-3 font-bold font-sans tracking-wide"
                color="blue-gray"
              >
                Objetivos del grupo
              </Typography>
              <Typography className="mb-8 font-sans  text-blue-gray-500 text-left text-justify tracking-wide">
                <li>
                  Adquirir conocimiento permanente del estado del arte de las
                  ciencias de la computación, computación inteligente y
                  analítica computacional a nivel nacional e internacional para
                  su apropiación en la región como soporte tecnológico en los
                  diferentes sectores académicos, de investigación o industria.
                </li>
                <li>
                  Apropiar las tecnologías de ciencias de la computación,
                  computación inteligente y analítica computacional para el
                  almacenamiento, gestión, procesamiento y/o análisis de los
                  datos con modelado y soluciones computacionales en diferentes
                  áreas de aplicación.
                </li>
                <li>
                  Desarrollar habilidades y capacidades para soportar soluciones
                  con computación de alto desempeño que permitan el uso,
                  procesamiento o análisis eficiente o masivo de la información.
                </li>
              </Typography>
            </div>
            <div className="mx-auto flex w-full justify-center px-4 md:w-5/12 lg:mt-0">
              <Card className="shadow-lg shadow-gray-500/10">
                <CardHeader className="relative h-56">
                  <img
                    alt="AdaLab Logo"
                    src={process.env.PUBLIC_URL + "/Logo_AdaLab.png"}
                    className="h-full w-full"
                  />
                </CardHeader>
                {/*<CardBody>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-3 font-bold"
                  >
                    Objetivos del grupo
                  </Typography>
                  <Typography className="font-normal text-blue-gray-500">
                    Texto
                  </Typography>
                </CardBody>*/}
              </Card>
            </div>
          </div>
        </div>
      </section>
      <StatsCounter />
      <div
        style={{
          height: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h3"
          className="font-bold font-sans tracking-wide"
          color="blue-gray"
        >
          Noticias recientes
        </Typography>
      </div>
      <New showPaginator={false} noticiasPerPages={3} />
      <Footer />
    </>
  );
};
export default Home;
