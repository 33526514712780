import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import { useParams } from "react-router-dom";
import { API_URL } from "../App.js";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const InvestigationID = () => {
  const { projectId } = useParams();

  const defaultImage = "/Logo_AdaLab.png";

  const [proyectos, setProyecto] = useState(null);
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  useEffect(() => {
    fetch(API_URL + `investigacion/${projectId}`)
      .then((response) => response.json())
      .then((data) => {
        setProyecto(data.data);
      })
      .catch((error) => {
        console.log("Error al obtener los datos del proyecto:", error);
      });
  }, [projectId]);

  if (!proyectos) {
    return <p>Loading...</p>;
  }
  function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    );
  }

  return (
    <div>
      <br />
      <section className="body-font">
        <div className="container mx-auto">
          <h4 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 font-sans">
            {proyectos.proyecto[0].titulo}
          </h4>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center">
            <div className="sm:w-2/3 mb-10 px-4">
              <p className="leading-relaxed text-base text-justify font-sans">
                {proyectos.proyecto[0].descripcion}
              </p>
            </div>

            <div className="sm:w-1/3 mb-10 px-4">
              <Accordion>
                <AccordionHeader className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2">
                  Integrantes
                </AccordionHeader>
              </Accordion>
              <br />
              {proyectos.integrantes &&
                proyectos.integrantes.map((integrantesItem) => {
                  const integranteInfo = proyectos.listintegrantes.find(
                    (info) => info.id === integrantesItem.integrante_id
                  );

                  if (integranteInfo) {
                    return (
                      <>
                        <Link
                          to={`/peopleID/${integranteInfo.id}`}
                          className="block"
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href={`/peopleID/${integranteInfo.id}`}
                            key={integranteInfo.id}
                            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900 font-sans"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={
                                `https://adalab.unillanos.edu.co/media/${integranteInfo.foto}` ||
                                defaultImage
                              }
                              alt="Integrante"
                              layout="fill"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="w-20 h-20 text-white p-2 rounded-full"
                              viewBox="0 0 24 24"
                            />

                            <span
                              className="ml-3 text-xl font-sans"
                              style={{
                                textDecoration: "none !important",
                                textAlign: "left",
                              }}
                            >
                              {integranteInfo.nombres}{" "}
                              {integranteInfo.apellidos}
                            </span>
                          </a>
                        </Link>
                      </>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container mx-auto">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center">
            <div className="sm:w-1/2 mb-10 px-4">
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(2)}
                  className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2"
                >
                  Software
                </AccordionHeader>
                <AccordionBody>
                  {proyectos.software &&
                    proyectos.software.map((softwareItem) => (
                      <div key={softwareItem.id} className="mb-4">
                        <Link
                          to={`/softwareID/${softwareItem.id}`}
                          className="block"
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                            /*href={`/peopleID/${softwareItem.id}`}*/
                            href={`/softwareID/${softwareItem.id}`}
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={
                                softwareItem.ruta_imagen
                                  ? `https://adalab.unillanos.edu.co/media/${softwareItem.ruta_imagen}`
                                  : defaultImage
                              }
                              alt="Software"
                              layout="fill"
                              fill="none"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              className="w-20 h-20 text-white p-2 rounded-full"
                              viewBox="0 0 24 24"
                            />
                            <span
                              className="ml-3 text-xl"
                              style={{
                                textDecoration: "none !important",
                                textAlign: "left",
                              }}
                            >
                              {softwareItem.nombre}
                            </span>
                          </a>
                        </Link>
                      </div>
                    ))}
                </AccordionBody>
              </Accordion>
            </div>
            <div className="sm:w-1/2 mb-10 px-4">
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(2)}
                  className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2"
                >
                  Publicaciones
                </AccordionHeader>
                <AccordionBody>
                  {proyectos.publicacion &&
                    proyectos.publicacion.map((publicationItem) => (
                      <div key={publicationItem.id} className="mb-4">
                        <a
                          className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                          href={`/publicationsID/${publicationItem.id}`}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={defaultImage}
                            alt="Integrante"
                            layout="fill"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="w-20 h-20 text-white p-2 rounded-full"
                            viewBox="0 0 24 24"
                          />
                          <span
                            className="ml-3 text-xl"
                            style={{
                              textDecoration: "none !important",
                              textAlign: "left",
                            }}
                          >
                            {publicationItem.titulo}
                          </span>
                        </a>
                      </div>
                    ))}
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default InvestigationID;
