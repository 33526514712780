import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { API_URL } from "../App.js";
import {} from "@material-tailwind/react";

function New({ showPaginator, noticiasPerPages }) {
  const [noticias, setNoticias] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const noticiasPerPage = noticiasPerPages;

  function formatearFecha(fechaString) {
    const opciones = { year: "numeric", month: "long", day: "numeric" };
    const fecha = new Date(fechaString);
    return fecha.toLocaleDateString("es-ES", opciones);
  }

  useEffect(() => {
    fetch(API_URL)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "success") {
          setNoticias(data.data.noticias);
        }
      })
      .catch((error) => {
        console.error("Error al obtener las noticias:", error);
      });

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, [currentPage]);

  const indexOfLastNoticia = currentPage * noticiasPerPage;
  const indexOfFirstNoticia = indexOfLastNoticia - noticiasPerPage;
  const currentNoticias = noticias.slice(
    indexOfFirstNoticia,
    indexOfLastNoticia
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const truncateDescription = (description) => {
    if (description.length > 500) {
      return description.slice(0, 500) + "...";
    }
    return description;
  };

  return (
    <section className="container mx-auto flex flex-col items-center w-full py-10 px-10">
      {currentNoticias.map((noticia) => (
        <div
          key={noticia.id}
          className="flex flex-col lg:flex-row items-center justify-center"
        >
          <div className="w-33 h-33 mr-4 border border-black">
            <a
              href={`/noticiaID/${noticia.id}`}
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
              className="text-colorPrincipal "
            >
              <img
                alt="AdaLab Logo"
                src={`https://adalab.unillanos.edu.co/media/${noticia.imagen_portada}`}
                className="h-full w-full object-contain"
                style={{ width: "250px", height: "250px" }}
              />
            </a>
          </div>
          <div className="flex-1 items-center text-center lg:text-left font-sans">
            <div className="md:px-9 lg:ml-9 border-b">
              <a
                href={`/noticiaID/${noticia.id}`}
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
                className="text-colorPrincipal hover:text-colorSecudario hover:underline"
              >
                <h4 className="text-2xl font-medium mb-2 text-justify font-sans tracking-wide">
                  {noticia.titulo}
                </h4>
              </a>
              <div className="flex mb-2 lg:justify-start justify-center font-sans">
                <span className="flex items-center text-blue-gray-500 font-sans">
                  {formatearFecha(noticia.fecha_creacion)}
                </span>
              </div>

              <p
                className="mb-4 leading-relaxed text-justify text-sm font-sans"
                dangerouslySetInnerHTML={{
                  __html: truncateDescription(noticia.contenido),
                }}
              ></p>
            </div>
          </div>
        </div>
      ))}

      {/* Paginador */}
      {showPaginator && currentNoticias.length > 0 && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-2 py-1 rounded-md bg-colorPrincipal text-white"
          >
            &lt;
          </button>
          {Array(Math.ceil(noticias.length / noticiasPerPage))
            .fill()
            .map((_, index) => {
              if (
                index + 1 === 1 ||
                index + 1 === currentPage ||
                index + 1 === currentPage - 1 ||
                index + 1 === currentPage + 1 ||
                index + 1 === Math.ceil(noticias.length / noticiasPerPage)
              ) {
                return (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    className={`font-sans mx-1 py-1 px-3 rounded-md ${
                      currentPage === index + 1
                        ? "bg-colorPrincipal text-white"
                        : "bg-gray-300"
                    }`}
                  >
                    {index + 1}
                  </button>
                );
              } else if (
                index + 1 === currentPage - 2 ||
                index + 1 === currentPage + 2
              ) {
                return <span key={index}>...</span>;
              } else {
                return null;
              }
            })}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(noticias.length / noticiasPerPage)
            }
            className="px-2 py-1 rounded-md bg-colorPrincipal text-white"
          >
            &gt;
          </button>
        </div>
      )}

      {!showPaginator && currentNoticias.length > 0 && (
        <Link to={`/news`} className="block">
          <Button className="bg-colorPrincipal hover:bg-colorSecudario font-sans">
            Ver todas las noticias
          </Button>
        </Link>
      )}
    </section>
  );
}

export default New;
