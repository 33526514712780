import Navbarcomp2 from "./components/Navbar2";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Investigation from "./pages/investigation";
import People from "./pages/people";
import Publications from "./pages/publications";
import Software from "./pages/software";
import News from "./pages/news";
import Videos from "./pages/videos";
import InvestigationID from "./pages/investigationID";
import PublicationsID from "./pages/publicationsID";
import PeopleID from "./pages/peopleID";
import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import SoftwareID from "./pages/softwareID";
import NoticiaID from "./pages/noticiaID";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbarcomp2 />}>
            <Route index element={<Home />}></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route path="investigation" element={<Investigation />}></Route>
            <Route path="people" element={<People />}></Route>
            <Route path="publications" element={<Publications />}></Route>
            <Route path="software" element={<Software />}></Route>
            <Route path="videos" element={<Videos />}></Route>
            <Route
              path="investigationID/:projectId"
              element={<InvestigationID />}
            ></Route>
            <Route
              path="publicationsID/:publicId"
              element={<PublicationsID />}
            ></Route>
            <Route path="peopleID/:peopleId" element={<PeopleID />}></Route>
            <Route
              path="softwareID/:softwareId"
              element={<SoftwareID />}
            ></Route>
            <Route path="noticiaID/:noticiaId" element={<NoticiaID />}></Route>
            <Route path="news" element={<News />}></Route>
            <Route
              path="*"
              element={<Navigate replace to="/"></Navigate>}
            ></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

