import Footer from "../components/footer";
import React, { useState } from "react";
import "../assets/css/contact.css";
import Swal from "sweetalert2";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { API_URL } from "../App.js";

let iconDefault = L.icon({
  iconUrl: icon,
  iconShadow: iconShadow,
});

const markerPosition = [4.07441, -73.58393];

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    asunto: "",
    message: "",
  });

  const { name, email, asunto, message } = formData;
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleChange = (e) => {
    const newEmail = e.target.value;
    setFormData({ ...formData, [e.target.name]: newEmail });
    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
      newEmail
    );

    setIsEmailValid(isValidEmail);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid) {
      try {
        const response = await fetch(API_URL + "contacts/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const data = await response.json();

          Swal.fire({
            icon: "success",
            title: "Exito",
            text: data.message + ", te contactaremos pronto " + name,
          });
          setFormData({
            ...formData,
            name: "",
            email: "",
            asunto: "",
            message: "",
          });
        } else {
          console.error("Error al enviar el formulario:", response.statusText);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No se pudo enviar el mensaje, inténtalo de nuevo.",
          });
        }
      } catch (error) {
        console.error("Error al enviar el formulario:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No se pudo enviar el mensaje, inténtalo más tarde .",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: name + ", Por favor digita un correo válido.",
      });
    }
  };

  return (
    <div>
      <div className="bg-contact100">
        <div className="container-contact100">
          <div className="wrap-contact100">
            <img
              alt="contact"
              className="w-full md:w-[55%]"
              src="https://newslab.com.br/wp-content/uploads/2022/12/960x720-4-1.png"
            ></img>
            <form
              className="contact100-form validate-form"
              onSubmit={handleSubmit}
            >
              <span className="contact100-form-title font-sans">
                Contáctanos
              </span>

              <div
                className="wrap-input100 validate-input"
                data-validate="Name is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  value={name}
                  onChange={handleChange}
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
              </div>
              <div
                className={`wrap-input100 validate-input ${
                  !isEmailValid ? "border-red-500" : ""
                }`}
              >
                <input
                  className={`input100 ${!isEmailValid ? "text-red-500" : ""}`}
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>

              <div
                className="wrap-input100 validate-input"
                data-validate="Asunto is required"
              >
                <input
                  className="input100"
                  type="text"
                  name="asunto"
                  placeholder="Asunto"
                  value={asunto}
                  onChange={handleChange}
                  required
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <i className="fa fa-user" aria-hidden="true"></i>
                </span>
              </div>
              <div
                className="wrap-input100 validate-input"
                data-validate="Message is required"
              >
                <textarea
                  className="input100"
                  name="message"
                  placeholder="Mensaje"
                  value={message}
                  onChange={handleChange}
                  required
                ></textarea>
                <span className="focus-input100"></span>
              </div>
              <div className="container-contact100-form-btn">
                <button
                  className="contact100-form-btn bg-colorPrincipal hover:bg-colorSecudario"
                  type="submit"
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MapContainer
        center={markerPosition}
        zoom={50}
        scrollWheelZoom={false}
        className="contact100-map mb-6"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={markerPosition} icon={iconDefault}>
          <Popup>
            3er Piso <br /> Edificio Einstein.
          </Popup>
        </Marker>
      </MapContainer>
      <Footer />
    </div>
  );
};
export default Contact;
