import { useEffect, useState } from "react";
import Footer from "../components/footer";
import { Typography } from "@material-tailwind/react";
import { useParams } from "react-router-dom";
import { Button } from "@material-tailwind/react";

import { API_URL } from "../App.js";

const defaultImage = process.env.PUBLIC_URL + "Logo_AdaLab.png";
const goBack = () => {
  window.history.back();
};

function formatearFecha(fechaString) {
  const opciones = { year: "numeric", month: "long", day: "numeric" };
  const fecha = new Date(fechaString);
  return fecha.toLocaleDateString("es-ES", opciones);
}

const NoticiaID = () => {
  const { noticiaId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(API_URL + `noticia/${noticiaId}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Error al obtener los datos de la noticia:", error);
      });
  }, [noticiaId]);

  if (!data) {
    return (
      <p>
        <br />
        <Typography
          variant="h3"
          className="font-bold font-sans"
          color="white-gray"
        >
          Loading...
        </Typography>
      </p>
    );
  }

  return (
    <div>
      <section className="text-gray-900 body-font overflow-hidden mb-5">
        <div className="container px-5 py-10 mx-auto">
          <h1 className="text-gray-900 text-3xl title-font font-medium mb-2 font-sans">
            {data.noticia.titulo}
          </h1>
          <h2 className="text-sm title-font text-gray-600 tracking-widest text-left font-sans">
            Publicado: {formatearFecha(data.noticia.fecha_creacion)}
          </h2>
          <div className=" mx-auto">
            <img
              alt={data.noticia.titulo}
              className="lg:w-1/3 w-full lg:h-auto h-64 object-contain object-top rounded float-left mr-4"
              src={
                data.noticia.imagen_portada
                  ? `https://adalab.unillanos.edu.co/media/${data.noticia.imagen_portada}`
                  : defaultImage
              }
            />
            <p className="leading-relaxed font-sans text-justify">
              <div
                dangerouslySetInnerHTML={{ __html: data.noticia.contenido }}
              />
            </p>
          </div>
          <div className="clear-both"></div>
        </div>
        <Button onClick={goBack} className="bg-colorSecudario font-sans">
          Volver
        </Button>
      </section>
      <Footer />
    </div>
  );
};

export default NoticiaID;
