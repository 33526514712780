import { Fragment } from "react";
import { Link, Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { FaLaptop } from "react-icons/fa";
import { LuBookMarked } from "react-icons/lu";
import { AiOutlineHome, AiOutlineFileSearch } from "react-icons/ai";
import { BsPeople } from "react-icons/bs";
import { MdOndemandVideo, MdOutlinePermContactCalendar } from "react-icons/md";
import "../assets/css/navbar.css";

const navigation = [
  { name: "Inicio", href: "/", current: false, icon: <AiOutlineHome /> },
  { name: "Integrantes", href: "/people", current: false, icon: <BsPeople /> },
  {
    name: "Investigación",
    href: "/investigation",
    current: false,
    icon: <AiOutlineFileSearch />,
  },
  {
    name: "Publicaciones",
    href: "/publications",
    current: false,
    icon: <LuBookMarked />,
  },
  { name: "Software", href: "/software", current: false, icon: <FaLaptop /> },
  {
    name: "Videos",
    href: "/videos",
    current: false,
    icon: <MdOndemandVideo />,
    hidden: true,
  },
  {
    name: "Contacto",
    href: "/contact",
    current: false,
    icon: <MdOutlinePermContactCalendar />,
    hidden: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavbarComp2 = () => {
  return (
    <>
      <Disclosure
        as="nav"
        className="navbar-transparent bg-white border-b border-colorPrincipal "
      >
        {({ open }) => (
          <>
            <Container>
              <div className="relative flex h-16 items-center justify-between ">
                <div className="absolute inset-y-0  flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2  hover:bg-gray-700  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Abrir Menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-10 w-auto lg:hidden"
                      src={process.env.PUBLIC_URL + "/Logo_AdaLab.png"}
                      style={{ width: "100px", height: "120px" }}
                      alt="Adalab"
                    />
                    <img
                      as={Link}
                      to={"/"}
                      className="hidden h-10 w-auto lg:block"
                      src={process.env.PUBLIC_URL + "/Logo_AdaLab.png"}
                      style={{
                        width: "100px",
                        height: "120px",
                        overflow: "visible",
                      }}
                      alt="Adalab"
                    />
                  </div>

                  <div className="ml-auto text-right hidden sm:ml-6 sm:block pl-32">
                    <div className="flex space-x-4 justify-end">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className="rounded-md py-5 px-1 text-sm  no-underline font-sans"
                          aria-current={item.current ? "page" : undefined}
                          style={{
                            color: "black",
                            textDecoration: "none",
                          }}
                        >
                          <div className="flex items-center  hover:text-colorPrincipal">
                            <span className="mr-2">{item.name}</span>
                            {React.cloneElement(item.icon, {
                              className: "w-5 h-5",
                            })}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Container>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "text-white bg-gray-900"
                        : "hover:bg-colorPrincipal hover:text-white ",
                      "block rounded-md px-3 py-2 text-base font-bold",
                      "no-underline",
                      "transition-colors  ease-in-out"
                    )}
                    aria-current={item.current ? "page" : undefined}
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "white")}
                    onMouseLeave={(e) => (e.target.style.color = "black")}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <br />
      <br />
      <section>
        <Outlet />
      </section>
    </>
  );
};

export default NavbarComp2;
