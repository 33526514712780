import Footer from "../components/footer";
import "mdb-ui-kit/css/mdb.min.css";
import "../assets/css/people.css";
import React, { useEffect, useState } from "react";
import { API_URL, MEDIA_URL } from "../App.js";
import { Typography } from "@material-tailwind/react";
import { TeamCard } from "../components/team-card";

const People = () => {
  const URL = API_URL;
  const [integrantes, setIntegrantes] = useState({});
  const defaultImage = "/user.png";

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    fetch(URL + "integrantes")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al obtener los datos");
        }
        return response.json();
      })
      .then((data) => {
        setIntegrantes(
          data.data.reduce((acc, integrante) => {
            const tipo = integrante.tipo_integrante_id;
            if (acc[tipo]) {
              acc[tipo].push(integrante);
            } else {
              acc[tipo] = [integrante];
            }
            return acc;
          }, {})
        );
      })
      .catch((error) => {
        console.log("Error al obtener los datos:", error);
      });
  };

  const getTipoIntegrante = (tipo) => {
    let nombreTipo = "";
    if (tipo === "3") {
      nombreTipo = "Director";
    } else if (tipo === "5") {
      nombreTipo = "Profesores";
    } else if (tipo === "7") {
      nombreTipo = "Estudiantes de maestría";
    } else if (tipo === "6") {
      nombreTipo = "Estudiantes de pregrado";
    } else if (tipo === "8") {
      nombreTipo = "Egresados";
    } else if (tipo === "9") {
      nombreTipo = "Ex-integrantes";
    } else if (tipo === "10") {
      nombreTipo = "Colaboradores";
    }
    return nombreTipo;
  };
  if (!integrantes) {
    return (
      <p>
        <br />
        <Typography
          variant="h3"
          className="font-bold font-sans"
          color="white-gray"
        >
          Loading...
        </Typography>
      </p>
    );
  }
  return (
    <div>
      <section>
        <br></br>
        <Typography variant="h3" className="font-bold font-sans tracking-wide">
          Integrantes
        </Typography>
        <section className="px-4 pt-4 pb-48">
          <div className="container mx-auto">
            {Object.entries(integrantes).map(([tipo, grupo]) => (
              <div className="rounded-lg shadow-custom border-black mb-4 p-4">
                <div className="text-center ">
                  <h3 className="pb-6 font-sans rounded-md pt-2 tracking-wide ">
                    {getTipoIntegrante(tipo)}
                  </h3>
                </div>
                <div className="grid grid-cols-1 gap-x-24 md:grid-cols-2 xl:grid-cols-4 font-sans">
                  {grupo.map((integrante) => (
                    <>
                      <TeamCard
                        key={integrante.id}
                        img={
                          integrante.foto
                            ? MEDIA_URL + `media/${integrante.foto}`
                            : defaultImage
                        }
                        name={`${integrante.nombres} ${integrante.apellidos}`}
                        //position={getTipoIntegrante(tipo)}
                        id={integrante.id}
                      />
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
        {/*
        <div className="row text-center">
          {Object.entries(integrantes).map(([tipo, grupo]) => (
            <div key={tipo}>
              <h3>{getTipoIntegrante(tipo)}</h3>
              <div className="row">
                {grupo.map((integrante) => (
                  <div key={integrante.id} className="col-md-4 mb-2 mb-md-0">
                    <div className="card testimonial-card">
                      <div
                        className="card-up"
                        style={{ backgroundColor: "#2A2827" }}
                      ></div>
                      <div className="avatar mx-auto bg-white">
                        <img
                          src={`https://adalab.unillanos.edu.co/media/${integrante.foto}`}
                          className="rounded-circle img-fluid"
                          alt="avatar"
                          style={{ width: "110px", height: "100px" }}
                        />
                      </div>justify
                      <div className="card-body">
                        <h4 className="mb-4">
                          {`${integrante.nombres} ${integrante.apellidos}`}
                        </h4>
                        <hr />
                        <p className="dark-grey-text mt-4">
                          <i className="fas fa-quote-left pe-2 text-justify"></i>{" "}
                          {showFullDescription
                            ? integrante.biografia
                            : truncateDescription(integrante.biografia, 150)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>*/}
      </section>
      <Footer />
    </div>
  );
};
export default People;
