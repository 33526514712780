import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";

export function FeatureCard({ color, icon, title }) {
  return (
    <a href={`/investigation/`} className="no-underline relative flex flex-co">
      <Card className="rounded-2xl shadow-lg shadow-gray-500/10">
        <CardBody className="px-8 text-center">
          <IconButton
            variant="gradient"
            style={{ width: '80px', height: '80px' }}
            color={color}
            className="pointer-events-none mb-1 rounded-full "
          >
            {icon}
          </IconButton>
          <Typography variant="h5" className="mb-2 font-sans font-extrabold hover:text-colorPrincipal" color="blue-gray">
            {title}
          </Typography>
          {
            /* 
              <Typography className="font-sans text-blue-gray-600">
                {description}
            </Typography>*/
          }
        </CardBody>
      </Card>
    </a>
  );
}

FeatureCard.defaultProps = {
  color: "blue",
};

FeatureCard.propTypes = {
  color: PropTypes.oneOf([
    "blue-gray",
    "gray",
    "brown",
    "deep-orange",
    "orange",
    "amber",
    "yellow",
    "lime",
    "light-green",
    "green",
    "teal",
    "cyan",
    "light-blue",
    "blue",
    "indigo",
    "deep-purple",
    "purple",
    "pink",
    "red",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

FeatureCard.displayName = "/src/widgets/layout/feature-card.jsx";

export default FeatureCard;
