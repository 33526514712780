import { Fragment, useEffect, useState } from "react";
import Footer from "../components/footer";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { API_URL, MEDIA_URL } from "../App.js";
import { GiPlantsAndAnimals } from "react-icons/gi";
import { BsFillCpuFill } from "react-icons/bs";
import { AiFillMedicineBox } from "react-icons/ai";
import { Link } from "react-router-dom";

function Icon({ open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-5 w-5 transition-transform ${open ? "rotate-180" : ""}`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const defaultImage = process.env.PUBLIC_URL + "Logo_AdaLab.png";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};

const getIcon = (iconName) => {
  switch (iconName) {
    case "Biología computacional":
      return <GiPlantsAndAnimals />;
    case "Computación de alto desempeño":
      return <BsFillCpuFill />;
    case "Patología Computacional":
      return <AiFillMedicineBox />;
    default:
      return null;
  }
};

const Investigation = () => {
  const [open, setOpen] = useState({});
  const [data, setData] = useState(null);

  const handleOpen = (tipoId, pubId) => {
    setOpen((prevOpen) => {
      const isPubOpen = prevOpen[tipoId]?.includes(pubId);

      if (isPubOpen) {
        return {
          ...prevOpen,
          [tipoId]: prevOpen[tipoId].filter((id) => id !== pubId),
        };
      } else {
        return {
          ...prevOpen,
          [tipoId]: [...(prevOpen[tipoId] || []), pubId],
        };
      }
    });
  };

  useEffect(() => {
    fetch(API_URL + "publicaciones")
      .then((response) => response.json())
      .then((responseData) => {
        const initialOpenState = {};
        responseData.data.tipo_publicacion.forEach((tipo) => {
          initialOpenState[tipo.id] = [];
        });

        if (responseData.data) {
          setOpen(initialOpenState);
          setData(responseData.data);
        } else {
          console.error("No se recibieron los datos esperados de la API");
        }
      })
      .catch((error) => console.error(error));
  }, []);

  if (!data) {
    return (
      <>
        <br />
        <Typography variant="h3" className="font-bold font-sans" color="gray">
          Loading...
        </Typography>
      </>
    );
  }

  return (
    <div>
      <br />
      <Typography variant="h3" className="font-bold font-sans" color="black">
        Publicaciones
      </Typography>
      <div className="m-10 font-sans">
        {data.tipo_publicacion.map((tipo, index) => (
          <Fragment key={tipo.id}>
            <Accordion
              open={open[tipo.id]?.includes(null)}
              icon={<Icon open={open[tipo.id]?.includes(null)} />}
            >
              <AccordionHeader
                onClick={() => handleOpen(tipo.id, null)}
                className="bg-colorPrincipal px-4 rounded-t-lg text-white text-left font-sans"
              >
                <div className="flex items-center">
                  {getIcon(tipo.nombre)}
                  <span className="ml-2">{tipo.nombre}</span>
                </div>
              </AccordionHeader>
              <AccordionBody>
                <div className="flex flex-wrap text-center">
                  {data.publicacion
                    .filter((pub) => pub.tipo_publicacion_id === tipo.id)
                    .map((pub) => (
                      <Card className="mt-6 w-96 mx-1 mb-4 " key={pub.id}>
                        <CardHeader className="relative h-56 flex justify-center x">
                          <img
                            src={
                              pub.ruta_imagen
                                ? MEDIA_URL + `media/${pub.ruta_imagen}`
                                : defaultImage
                            }
                            alt="imagen Proyecto"
                            layout="fill"
                          />
                        </CardHeader>
                        <CardBody>
                          <Typography
                            variant="h5"
                            color="blue-gray"
                            className="mb-2 font-sans "
                          >
                            {pub.titulo}
                          </Typography>
                          <Typography className="mb-2 font-sans text-justify">
                            {`${truncateText(
                              `${pub.descripcion} - ${pub.rev_eve}`,
                              320
                            )}`}
                          </Typography>
                        </CardBody>
                        <CardFooter className="pt-0 items-end justify-content-end">
                          <Link
                            to={`/publicationsID/${pub.id}`}
                            className="block"
                          >
                            <Button
                              className="bg-colorPrincipal hover:bg-colorSecudario "
                              bottom="auto"
                            >
                              Leer más
                            </Button>
                          </Link>
                        </CardFooter>
                      </Card>
                    ))}
                </div>
              </AccordionBody>
            </Accordion>
            {index !== data.tipo_publicacion.length - 1 && <br />}{" "}
          </Fragment>
        ))}
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Investigation;
