import PropTypes from "prop-types";
import { Card, Avatar, Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function TeamCard({ img, name, position, socials, id }) {
  return (
    <Card key={id} color="transparent" shadow={false} className="text-center">
      <Avatar
        src={img}
        alt={name}
        size="md"
        className="h-40 w-120 shadow-lg shadow-gray-500/25 object-cover"
      />
      <Link to={`/peopleID/${id}`} className="block" style={{ textDecoration: "none" }}>
        <Typography variant="h6" color="blue-gray" className="mt-6 mb-1 font-sans" >
          {name}
        </Typography>
      </Link>
      {position && (
        <Link to={`/peopleID/${id}`} className="block" style={{ textDecoration: "none" }}>
          <Typography className=" text-blue-gray-500 font-serif">
            {position}
          </Typography>
        </Link>
      )
      }


      {/*{socials && <div className="mx-auto mt-5">{socials}</div>}*/}
    </Card >
  );
}

TeamCard.defaultProps = {
  position: "",
  socials: null,
};

TeamCard.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  socials: PropTypes.node,
};

TeamCard.displayName = "/src/widgets/layout/team-card.jsx";

export default TeamCard;
