import { Fragment, useEffect, useState } from "react";
import Footer from "../components/footer";
import Modal from "react-modal";
import {
  Accordion,
  AccordionHeader,
  Typography,
} from "@material-tailwind/react";
import { Link, useParams } from "react-router-dom";

import { API_URL, MEDIA_URL } from "../App.js";

const defaultImage = "/Logo_AdaLab.png";
const defaultuser = "/user.png";

const PublicationsID = () => {
  const { publicId } = useParams();
  const [data, setData] = useState(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    fetch(API_URL + `publicaciones/${publicId}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Error al obtener los datos del proyecto:", error);
      });
  }, [publicId]);

  if (!data) {
    return (
      <p>
        <br />
        <Typography variant="h3" className="font-bold font-sans" color="black">
          Loading...
        </Typography>
      </p>
    );
  }
  const modalStyle = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
    content: {
      position: "relative",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      maxWidth: "80%",
      maxHeight: "80%",
    },
  };

  return (
    <div>
      <br />
      <section className="text-gray-600 body-font">
        <div className="container  py-5 mx-auto flex flex-col">
          <div className="w-full mx-auto sm:w-full">
            <h4 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              {data.publicacion[0].titulo}
            </h4>
            <div className="rounded-lg h-64 overflow-auto group relative">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Imagen Modal"
                style={modalStyle}
              >
                <img
                  alt="Imagen"
                  className="object-cover  object-center h-full w-full"
                  src={
                    data.publicacion[0].ruta_imagen
                      ? MEDIA_URL + `media/${data.publicacion[0].ruta_imagen}`
                      : defaultImage
                  }
                />
              </Modal>
              <img
                alt="Imagen"
                className="object-cover cursor-pointer object-center h-full w-full transition-opacity duration-300 ease-in-out group-hover:opacity-80 hover:scale-110"
                src={
                  data.publicacion[0].ruta_imagen
                    ? MEDIA_URL + `media/${data.publicacion[0].ruta_imagen}`
                    : defaultImage
                }
                onClick={openModal}
              />
            </div>
            <div className="sm:w-3/3 mt-5 px-4">
              <p className="leading-relaxed text-base text-justify">
                {data.publicacion[0].descripcion}
              </p>
            </div>
            <div className="w-full flex flex-col sm:flex-row mt-10">
              <div className="sm:w-4/7 text-left ">
                <div className="flex items-left gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>

                  <span className="text-gray-800">
                    <span className="font-bold">Tipo:</span>{" "}
                    {data?.publicacion[0].tipo_publicacion_id === 1
                      ? "Artículos científicos"
                      : data?.publicacion[0].tipo_publicacion_id === 2
                      ? "Conferencias"
                      : "Desconocido"}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                  <span className="text-gray-800">
                    <span className="font-bold">Fecha: </span>{" "}
                    {data.publicacion[0].fecha_publicacion}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>

                  <span className="text-gray-800">
                    <span className="font-bold">Revista/Evento: </span>{" "}
                    {data.publicacion[0].rev_eve}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                    />
                  </svg>

                  <span className="text-gray-800">
                    <span className="font-bold">Volúmen:</span>{" "}
                    {data.publicacion[0].volumen}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                    />
                  </svg>

                  <span className="text-gray-800">
                    <span className="font-bold">Páginas:</span>{" "}
                    {data.publicacion[0].numpag}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      stroke-linejoin="round"
                      d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
                    />
                  </svg>

                  <span className="text-gray-800">
                    <span className="font-bold">ISSN:</span>{" "}
                    {data?.publicacion[0].issn}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                    />
                  </svg>

                  <a
                    href={data?.publicacion[0].doi}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-700"
                    style={{ textDecoration: "none" }}
                  >
                    <span className="font-bold">DOI:</span>{" "}
                    {data?.publicacion[0].doi}
                  </a>
                </div>
                <div className="flex items-center gap-2 overflow-hidden">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                    />
                  </svg>

                  <a
                    href={data?.publicacion[0].url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-700"
                    style={{ textDecoration: "none" }}
                  >
                    <span className="font-bold">URL:</span>{" "}
                    {data?.publicacion[0].url}
                  </a>
                </div>
              </div>
              <div className="sm:w-3/7 sm:pl-8  sm:border-l border-gray-200 sm:border-t-0 border-t  items-end">
                <Accordion>
                  <AccordionHeader className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2">
                    Autor(es)
                  </AccordionHeader>
                </Accordion>
                <br />
                {data.integrantes &&
                  data.integrantes.map((integrantesItem) => {
                    const integranteInfo = data.listintegrantes.find(
                      (info) => info.id === integrantesItem.integrante_id
                    );

                    if (integranteInfo) {
                      return (
                        <>
                          <Link
                            to={`/peopleID/${integranteInfo.id}`}
                            className="block"
                            style={{ textDecoration: "none" }}
                          >
                            <a
                              key={integranteInfo.id}
                              href={`/peopleID/${integranteInfo.id}`}
                              className="flex title-font font-medium items-center md:justify-start justify-left text-gray-900"
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src={
                                  integranteInfo.foto
                                    ? MEDIA_URL + `media/${integranteInfo.foto}`
                                    : defaultuser
                                }
                                alt="Integrante"
                                layout="fill"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-20 h-20 text-white p-2 rounded-full "
                                viewBox="0 0 24 24"
                              />

                              <span
                                className="ml-3 text-base "
                                style={{ textDecoration: "none !important" }}
                              >
                                {integranteInfo.nombres}{" "}
                                {integranteInfo.apellidos}
                              </span>
                            </a>
                          </Link>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PublicationsID;
