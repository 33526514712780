import { Fragment, useEffect, useState } from "react";
import Footer from "../components/footer";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { API_URL } from "../App.js";
import { GiPlantsAndAnimals } from "react-icons/gi";
import { BsFillCpuFill } from "react-icons/bs";
import { AiFillMedicineBox } from "react-icons/ai";
import { Link } from "react-router-dom";

function Icon({ open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`h-5 w-5 transition-transform ${open ? "rotate-180" : ""}`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const defaultImage = process.env.PUBLIC_URL + "Logo_AdaLab.png";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + "...";
};

const getIcon = (iconName) => {
  switch (iconName) {
    case "Biología computacional":
      return <GiPlantsAndAnimals />;
    case "Computación de alto desempeño":
      return <BsFillCpuFill />;
    case "Patología Computacional":
      return <AiFillMedicineBox />;
    default:
      return null;
  }
};

const Investigation = () => {
  const [open, setOpen] = useState({});
  const [data, setData] = useState(null);

  const handleOpen = (temaId, subtemaId) => {
    setOpen((prevOpen) => {
      const isSubtemaOpen = prevOpen[temaId]?.includes(subtemaId);

      if (isSubtemaOpen) {
        return {
          ...prevOpen,
          [temaId]: prevOpen[temaId].filter((id) => id !== subtemaId),
        };
      } else {
        return {
          ...prevOpen,
          [temaId]: [...(prevOpen[temaId] || []), subtemaId],
        };
      }
    });
  };

  useEffect(() => {
    fetch(API_URL + "investigacion")
      .then((response) => response.json())
      .then((responseData) => {
        const initialOpenState = {};
        responseData.data.tema.forEach((tema) => {
          initialOpenState[tema.id] = [null];
        });
        setOpen(initialOpenState);
        setData(responseData.data);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!data) {
    return (
      <>
        <br />
        <Typography variant="h3" className="font-bold font-sans" color="black">
          Loading...
        </Typography>
      </>
    );
  }

  return (
    <div>
      <br />
      <Typography variant="h3" className="font-bold font-sans" color="black">
        Investigación
      </Typography>
      <div className="m-10 font-sans">
        {data.tema.map((tema) => (
          <Fragment key={tema.id}>
            <Accordion
              open={open[tema.id]?.includes(null)}
              icon={<Icon open={open[tema.id]?.includes(null)} />}
            >
              <AccordionHeader
                onClick={() => handleOpen(tema.id, null)}
                className="bg-colorPrincipal px-4 rounded-t-lg text-white text-left font-sans"
              >
                <div className="flex items-center">
                  {getIcon(tema.nombre)}
                  <span className="ml-2">{tema.nombre}</span>
                </div>
              </AccordionHeader>
              <AccordionBody>
                {data.subtema
                  .filter((subtema) => subtema.tema_id === tema.id)
                  .map((subtema) => (
                    <Accordion
                      key={subtema.id}
                      open={open[tema.id]?.includes(subtema.id)}
                      icon={<Icon open={open[tema.id]?.includes(subtema.id)} />}
                    >
                      <AccordionHeader
                        onClick={() => handleOpen(tema.id, subtema.id)}
                        className="bg-colorSecudario px-4 py-2 rounded-t-lg text-white font-sans"
                      >
                        {subtema.nombre}
                      </AccordionHeader>
                      <AccordionBody>
                        <div className="flex flex-wrap font-sans">
                          {data.proyecto
                            .filter(
                              (proyecto) => proyecto.subtema_id === subtema.id
                            )
                            .map((proyecto) => {
                              const proyectoDes = data.proyectoDes.find(
                                (p) => p.id === proyecto.proyecto_id
                              );
                              const rutaImagen =
                                proyectoDes?.ruta_imagen || defaultImage;
                              const descripcionTruncada = truncateText(
                                proyectoDes?.descripcion || "",
                                320
                              );

                              return (
                                <Card
                                  key={proyecto.proyecto_id}
                                  className="mt-6 w-96 mx-2 font-sans"
                                >
                                  <CardHeader className="relative h-56 flex justify-center items-center font-sans">
                                    <img
                                      src={rutaImagen}
                                      alt="imagen Proyecto"
                                      layout="fill"
                                    />
                                  </CardHeader>
                                  <CardBody>
                                    <Typography
                                      variant="h5"
                                      color="blue-gray"
                                      className="mb-2 font-sans text-center"
                                    >
                                      {proyectoDes?.titulo}
                                    </Typography>
                                    <Typography className="mb-2 font-sans text-justify ">
                                      {descripcionTruncada}
                                    </Typography>
                                  </CardBody>
                                  <CardFooter className="pt-0">
                                    <Link
                                      to={`/investigationID/${proyecto.proyecto_id}`}
                                      className="block"
                                    >
                                      <Button className="bg-colorSecudario font-sans">
                                        Leer más
                                      </Button>
                                    </Link>
                                  </CardFooter>
                                </Card>
                              );
                            })}
                        </div>
                      </AccordionBody>
                    </Accordion>
                  ))}
              </AccordionBody>
            </Accordion>
          </Fragment>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Investigation;
