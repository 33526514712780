import Footer from "../components/footer";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Pagination } from "react-bootstrap";
import { Typography } from "@material-tailwind/react";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 6;
  function formatearFecha(fechaString) {
    const opciones = { year: "numeric", month: "long", day: "numeric" };
    const fecha = new Date(fechaString);
    return fecha.toLocaleDateString("es-ES", opciones);
  }

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/youtube/v3/search",
          {
            params: {
              part: "snippet",
              channelId: "UCHeKva8qnGCTrVv0zEI2ubA", // Reemplaza con el ID de tu canal de YouTube
              maxResults: 50, // Cantidad total de videos a obtener
              order: "date", // Ordenar por fecha (del más reciente al más antiguo)

              key: "AIzaSyA4xOd0ESFJvBb7p0f5tQY3qCjPG00CbrM", // Reemplaza con tu propia clave de API de YouTube
            },
          }
        );

        // Filtrar los videos disponibles (que tienen un ID de video válido)
        const availableVideos = response.data.items.filter(
          (video) => video.id.videoId
        );

        setVideos(availableVideos);
      } catch (error) {
        console.log(error);
      }
    };

    fetchVideos();
  }, []);

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <br></br>
      <Typography
        variant="h3"
        className="mb-3 font-bold font-sans"
        color="blue-gray"
      >
        Videos canal de Adalab - Automatic Data-driven Analytics Laboratory
      </Typography>
      <div className="row px-10">
        {currentVideos.map((video) => (
          <div key={video.id.videoId} className="col-lg-4 col-md-6 mb-4 ">
            <Card>
              <div className="embed-responsive embed-responsive-16by9 video-frame text-center mx-auto">
                <iframe
                  className="embed-responsive-item"
                  src={`https://www.youtube.com/embed/${video.id.videoId}`}
                  allowFullScreen
                  title={video.snippet.title}
                ></iframe>
              </div>
              <Card.Body>
                <Card.Title className="font-sans">
                  {video.snippet.title}
                </Card.Title>
                {/** <Card.Text className="font-sans">
                  {video.snippet.description}
                </Card.Text>*/}
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Publicado el: {formatearFecha(video.snippet.publishedAt)}
                </small>
              </Card.Footer>
            </Card>
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center">
        <Pagination>
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          <Pagination.Item active>{currentPage}</Pagination.Item>
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === Math.ceil(videos.length / videosPerPage)}
          />
        </Pagination>
      </div>

      <Footer />
    </div>
  );
};

export default Videos;
