import { Avatar, Typography } from "@material-tailwind/react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Footer from "../components/footer";
import { BsCalendarWeekFill, BsFillBookmarkCheckFill } from "react-icons/bs";
import {
  AiFillMail,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";
import {
  SiMicrosoftacademic,
  SiGooglescholar,
  SiAcademia,
} from "react-icons/si";
import { FaResearchgate, FaMendeley, FaOrcid, FaPager } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { API_URL, MEDIA_URL } from "../App.js";

export function Profile() {
  const defaultImage = "/user.png";
  const defaultImage2 = "/Logo_AdaLab.png";
  const { peopleId } = useParams();
  const [data, setData] = useState(null);
  const [open, setOpen] = React.useState(3);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const [cantidadProyectos, setcantidadProyectos] = useState(0);
  const [cantidadSoftware, setcantidadSoftware] = useState(0);
  const [cantidadPublicaciones, setcantidadPublicaciones] = useState(0);

  function Icon({ open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-5 w-5 transition-transform ${open ? "rotate-180" : ""}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
  }
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    fetch(API_URL + `integrantes/${peopleId}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
        setcantidadProyectos(data.data.proyectos.length);
        setcantidadSoftware(data.data.Software.length);
        setcantidadPublicaciones(data.data.Publicaciones.length);
      })
      .catch((error) => {
        console.log("Error al obtener los datos del proyecto:", error);
      });
  }, [peopleId]);

  if (!data) {
    return (
      <p>
        <br />
        <Typography
          variant="h3"
          className="font-bold font-sans"
          color="white-gray"
        >
          Loading...
        </Typography>
      </p>
    );
  }

  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 py-16 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12">
                  <div className="relative">
                    <div className="-mt-20 w-100 ">
                      <Avatar
                        src={
                          data.integrante.foto
                            ? MEDIA_URL + `media/${data.integrante.foto}`
                            : defaultImage
                        }
                        alt={data.integrante.foto}
                        variant="circular"
                        className="h-full w-full shadow-xl"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10 flex w-full justify-center px-4 lg:order-3 lg:mt-0 lg:w-4/12 lg:justify-end lg:self-center"></div>
                <div className="w-full px-4 lg:order-1 lg:w-4/12">
                  <div className="flex justify-center py-4 pt-8 lg:pt-4 font-sans">
                    <div className="mr-4 p-3 text-center">
                      <Typography
                        variant="lead"
                        color="blue-gray"
                        className="font-bold uppercase text-2xl"
                      >
                        {cantidadProyectos}
                      </Typography>
                      <Typography
                        variant="small"
                        className="font-normal text-blue-gray-500"
                      >
                        Proyectos
                      </Typography>
                    </div>
                    <div className="mr-4 p-3 text-center">
                      <Typography
                        variant="lead"
                        color="blue-gray"
                        className="font-bold uppercase text-2xl"
                      >
                        {cantidadPublicaciones}
                      </Typography>
                      <Typography
                        variant="small"
                        className="font-normal text-blue-gray-500"
                      >
                        Publicaciones
                      </Typography>
                    </div>
                    <div className="p-3 text-center lg:mr-4">
                      <Typography
                        variant="lead"
                        color="blue-gray"
                        className="font-bold uppercase text-2xl"
                      >
                        {cantidadSoftware}
                      </Typography>
                      <Typography
                        variant="small"
                        className="font-normal text-blue-gray-500"
                      >
                        Software
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-8 text-center ">
                <Typography
                  variant="h2"
                  color="blue-gray"
                  className="mb-2 font-sans"
                >
                  {`${data.integrante.nombres}  ${data.integrante.apellidos}`}
                </Typography>

                <div className="mb-2 flex items-center justify-center gap-2">
                  <AiFillMail className="h-5 w-5 text-blue-gray-700" />
                  <Typography className="font-medium text-blue-gray-800 font-sans">
                    Correo: {""}
                    {data.integrante.correo}
                  </Typography>
                </div>
                <div className=" border-t border-blue-gray-50 text-center">
                  <div className=" flex flex-wrap justify-center">
                    <div className="flex w-full flex-col items-center px-4 lg:w-10/12 text-justify">
                      <Typography className="mb-2 font-normal text-blue-gray-500 font-sans">
                        {data.integrante.biografia}
                      </Typography>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-6/10 text-center sm:pr-8 sm:py-8">
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <BsFillBookmarkCheckFill className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">CVLAC: </span>{" "}
                        {truncateText(data.integrante.cvlac, 70)}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <SiGooglescholar className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Google Scholar: </span>{" "}
                        {truncateText(data.integrante.scholar, 50)}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <SiMicrosoftacademic className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Microsoft Academic: </span>{" "}
                        {truncateText(data.integrante.macademic, 50)}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <FaResearchgate className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Researchgate: </span>{" "}
                        {data.integrante.rgate}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <FaMendeley className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Mendeley: </span>{" "}
                        {data.integrante.mendeley}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-left gap-2">
                      <AiFillLinkedin className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">LinkedIn: </span>{" "}
                        {data.integrante.linkedin}
                      </span>
                    </div>
                  </div>
                  <div className="sm:w-4/10 text-center sm:pr-8 sm:py-8">
                    <div className="mb-2 flex items-center justify-right gap-2">
                      <BsCalendarWeekFill className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Fecha de ingreso: </span>{" "}
                        {data.integrante.fecha_ingreso}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-right gap-2">
                      <FaPager className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Sitio Web: </span>{" "}
                        {data.integrante.sitio_web}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-right gap-2">
                      <FaOrcid className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">ORCID: </span>{" "}
                        {data.integrante.orcid}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-right gap-2">
                      <SiAcademia className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Academia: </span>{" "}
                        {data.integrante.academia}
                      </span>
                    </div>
                    <div className="mb-2 flex items-center justify-right gap-2">
                      <AiFillTwitterCircle className="-mt-px h-4 w-4 text-colorSecudario" />
                      <span className="text-gray-800">
                        <span className="font-bold">Twitter: </span>{" "}
                        {data.integrante.twitter}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" px-4">
              <Accordion
                open={open === 3}
                icon={<Icon open={open[data.id]?.includes(null)} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(3)}
                  className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2"
                >
                  Proyectos
                </AccordionHeader>
                <AccordionBody className="font-sans">
                  {data.proyectos &&
                    data.proyectos.map((proyectosItem) => {
                      const integranteInfo = data.listproyecto.find(
                        (info) => info.id === proyectosItem.proyecto_id
                      );
                      if (integranteInfo) {
                        return (
                          <>
                            <Link
                              to={`/investigationID/${integranteInfo.id}`}
                              className="block font-sans"
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href={`/investigationID/${integranteInfo.id}`}
                                key={integranteInfo.id}
                                className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={
                                    integranteInfo.ruta_imagen
                                      ? MEDIA_URL +
                                        `media/${integranteInfo.ruta_imagen}`
                                      : defaultImage2
                                  }
                                  alt="Integrante"
                                  layout="fill"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  className="w-20 h-20 text-white p-2 rounded-full"
                                  viewBox="0 0 24 24"
                                />

                                <span
                                  className="ml-3 text-lg "
                                  style={{
                                    textDecoration: "none !important",
                                    textAlign: "justify",
                                  }}
                                >
                                  {integranteInfo.titulo}
                                </span>
                              </a>
                            </Link>
                          </>
                        );
                      }
                      return integranteInfo;
                    })}
                </AccordionBody>
              </Accordion>
            </div>
            <div className="px-4">
              <Accordion
                open={open === 1}
                icon={<Icon open={open[data.id]?.includes(null)} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2"
                >
                  Software
                </AccordionHeader>
                <AccordionBody className="font-sans">
                  {data.Software &&
                    data.Software.map((softwareItem) => {
                      const softwareInfo = data.listsoftware.find(
                        (info) => info.id === softwareItem.software_id
                      );
                      if (softwareInfo) {
                        return (
                          <>
                            <Link
                              to={`/softwareID/${softwareInfo.id}`}
                              className="block"
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href={`/softwareID/${softwareInfo.id}`}
                                key={softwareInfo.id}
                                className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={
                                    softwareInfo.ruta_imagen
                                      ? MEDIA_URL +
                                        `media/${softwareInfo.ruta_imagen}`
                                      : defaultImage2
                                  }
                                  alt="software"
                                  layout="fill"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  className="w-20 h-20 text-white p-2 rounded-full"
                                  viewBox="0 0 24 24"
                                />

                                <span
                                  className="ml-3 text-lg"
                                  style={{ textDecoration: "none !important" }}
                                >
                                  {softwareInfo.nombre}
                                </span>
                              </a>
                            </Link>
                          </>
                        );
                      }
                      return softwareInfo;
                    })}
                </AccordionBody>
              </Accordion>
            </div>
            <div className=" px-4">
              <Accordion
                open={open === 2}
                icon={<Icon open={open[data.id]?.includes(null)} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(2)}
                  className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2"
                >
                  Publicaciones
                </AccordionHeader>
                <AccordionBody className="font-sans">
                  {data.Publicaciones &&
                    data.Publicaciones.map((publicacionesItem) => {
                      const integranteInfo = data.listpublicacion.find(
                        (info) => info.id === publicacionesItem.publicacion_id
                      );
                      if (integranteInfo) {
                        return (
                          <>
                            <Link
                              to={`/publicationsID/${integranteInfo.id}`}
                              className="block"
                              style={{ textDecoration: "none" }}
                            >
                              <a
                                href={`/publicationsID/${integranteInfo.id}`}
                                key={integranteInfo.id}
                                className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={
                                    integranteInfo.ruta_imagen
                                      ? MEDIA_URL +
                                        `media/${integranteInfo.ruta_imagen}`
                                      : defaultImage2
                                  }
                                  alt="Integrante"
                                  layout="fill"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  className="w-20 h-20 text-white p-2 rounded-full"
                                  viewBox="0 0 24 24"
                                />

                                <span
                                  className="ml-3 text-lg "
                                  style={{
                                    textDecoration: "none !important",
                                    textAlign: "justify",
                                  }}
                                >
                                  {integranteInfo.titulo}
                                </span>
                              </a>
                            </Link>
                          </>
                        );
                      }
                      return integranteInfo;
                    })}
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Profile;
