import { Fragment, useEffect, useState } from "react";
import Footer from "../components/footer";
import {
  Accordion,
  AccordionHeader,
  Typography,
} from "@material-tailwind/react";
import { Link, useParams } from "react-router-dom";

import { API_URL } from "../App.js";

const defaultImage = "/Logo_AdaLab.png";
const defaultuser = "/user.png";

const SoftwareID = () => {
  const { softwareId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(API_URL + `software/${softwareId}`)
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log("Error al obtener los datos del proyecto:", error);
      });
  }, [softwareId]);

  if (!data) {
    return (
      <>
        <br />
        <Typography variant="h3" className="font-bold font-sans" color="black">
          Loading...
        </Typography>
      </>
    );
  }

  return (
    <div>
      <br />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-5 mx-auto flex flex-col">
          <div className="lg:w-6/6 mx-auto">
            <h4 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              {data.software[0].nombre}
            </h4>

            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-3/5  px-4">
                <p className="leading-relaxed text-base text-justify">
                  {data.software[0].descripcion}
                </p>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-indigo-700"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                  <a
                    className="text-gray-800"
                    href={data.software[0].url_software}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className="font-bold"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      Instalador:{" "}
                    </span>{" "}
                    {data.software[0].url_software}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-indigo-700"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                  <a
                    className="text-gray-800"
                    href={`https://adalab.unillanos.edu.co/media/${data.software[0].url_repositorio}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className="font-bold"
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      Repositorio:{" "}
                    </span>{" "}
                    {data.software[0].url_repositorio}
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <svg
                    className="h-5 w-5 text-indigo-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-indigo-700"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                    />
                  </svg>

                  <a
                    className="text-gray-800"
                    href={`https://adalab.unillanos.edu.co/media/${data.software[0].ruta_manual}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      className="font-bold"
                      style={{ textDecoration: "none" }}
                    >
                      Manual:{" "}
                    </span>{" "}
                    {data.software[0].ruta_manual}
                  </a>
                </div>
                <Accordion>
                  <AccordionHeader className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2">
                    Proyecto
                  </AccordionHeader>
                </Accordion>
                <br />
                {data.proyecto &&
                  data.proyecto.map((proyectoItem) => {
                    const proyectoInfo = data.software.find(
                      (info) => info.proyecto_id === proyectoItem.id
                    );

                    if (proyectoInfo) {
                      return (
                        <Link
                          to={`/investigationID/${proyectoInfo.id}`}
                          className="block"
                          key={proyectoInfo.id}
                          style={{ textDecoration: "none" }}
                        >
                          <a
                            href={`/investigationID/${proyectoInfo.id}`}
                            className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
                            style={{ textDecoration: "none" }}
                          >
                            <img
                              src={
                                proyectoItem.ruta_imagen
                                  ? `https://adalab.unillanos.edu.co/media/${proyectoItem.ruta_imagen}`
                                  : defaultImage
                              }
                              alt="Proyecto"
                              className="w-20 h-20 text-white p-2 rounded-full"
                            />

                            <span className="ml-3 text-xl text-justify">
                              {proyectoItem.titulo}
                            </span>
                          </a>
                        </Link>
                      );
                    }
                    return null;
                  })}
              </div>

              <div className="sm:w-2/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t  pt-4 sm:mt-0 text-center sm:text-left">
                <div className="rounded-lg h-64 overflow-hidden">
                  <img
                    alt="Imagen"
                    className="object-cover object-center h-full w-full"
                    src={
                      data.software[0].ruta_imagen
                        ? `https://adalab.unillanos.edu.co/media/${data.software[0].ruta_imagen}`
                        : defaultImage
                    }
                  />
                </div>
                <Accordion>
                  <AccordionHeader className="bg-colorPrincipal px-4 py-2 rounded-t-lg text-white font-sans mt-2">
                    Desarrollador(es)
                  </AccordionHeader>
                </Accordion>
                <br />
                {data.integrantes &&
                  data.integrantes.map((integrantesItem) => {
                    const integranteInfo = data.listintegrantes.find(
                      (info) => info.id === integrantesItem.integrante_id
                    );

                    if (integranteInfo) {
                      return (
                        <>
                          <Link
                            to={`/peopleID/${integranteInfo.id}`}
                            className="block"
                            style={{ textDecoration: "none" }}
                          >
                            <a
                              href={`/peopleID/${integranteInfo.id}`}
                              key={integranteInfo.id}
                              className="flex  title-font font-medium items-center md:justify-start content-start text-gray-900"
                              style={{ textDecoration: "none" }}
                            >
                              <img
                                src={
                                  integranteInfo.foto
                                    ? `https://adalab.unillanos.edu.co/media/${integranteInfo.foto}`
                                    : defaultuser
                                }
                                alt="Integrante"
                                layout="fill"
                                fill="none"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                className="w-20 h-20 text-white p-2 rounded-full"
                                viewBox="0 0 24 24"
                              />

                              <span
                                className="ml-3 text-xl"
                                style={{
                                  textDecoration: "none !important",
                                  textAlign: "left",
                                }}
                              >
                                {integranteInfo.nombres}{" "}
                                {integranteInfo.apellidos}
                              </span>
                            </a>
                          </Link>
                        </>
                      );
                    }
                    return null;
                  })}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-3/5 text-center sm:pr-8 sm:py-8"></div>
              <div className="sm:w-2/5 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t  pt-4 sm:mt-0 text-center sm:text-left"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SoftwareID;
