import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../App.js";
import Footer from "../components/footer";
import { Typography } from "@material-tailwind/react";

const Software = () => {
  const [softwareData, setSoftwareData] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}software`)
      .then((response) => {
        const softwareInfo = response.data.data.software;
        setSoftwareData(softwareInfo);
      })
      .catch((error) => {
        console.error("Error fetching software data:", error);
      });
  }, []);

  return (
    <>
      <section className="mx-6 md:mx-8 lg:mx-32 xl:mx-50">
        <br />
        <Typography
          variant="h3"
          className="mb-3 font-bold font-sans ttracking-winder"
          color="blue-gray"
        >
          Software
        </Typography>
        <section>
          <div className="relative mx-auto max-w-7xl">
            <div className="grid max-w-lg gap-12 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
              {softwareData.map((item) => (
                <article
                  key={item.id}
                  className="flex flex-col mb-12 overflow-hidden cursor-pointer border rounded-lg shadow-lg border-black"
                >
                  <a href={`/softwareID/${item.id}`}>
                    <div className="flex-shrink-0">
                      <img
                        alt={item.nombre}
                        src={
                          item.ruta_imagen
                            ? `https://adalab.unillanos.edu.co/media/${item.ruta_imagen}`
                            : process.env.PUBLIC_URL + "/Logo_AdaLab.png"
                        }
                        className="object-cover w-full h-48 rounded-t-lg"
                      />
                    </div>
                  </a>
                  <div className="flex flex-col justify-between flex-1 p-4">
                    <div className="flex-1">
                      <a
                        href={`/softwareID/${item.id}`}
                        className="block mt-2 space-y-6"
                        style={{ textDecoration: "none" }}
                      >
                        <h5 className="text-lg font-semibold leading-tight text-black  font-sans">
                          {item.nombre}
                        </h5>
                        <p className="mt-2 text-base text-gray-800 text-justify font-sans">
                          {item.descripcion.length > 150
                            ? `${item.descripcion.slice(0, 150)}...`
                            : item.descripcion}
                        </p>
                      </a>
                    </div>
                    <div className="sm:flex sm:items-end sm:justify-center ">
                      <a
                        href={`/softwareID/${item.id}`}
                        className="block bg-colorPrincipal px-5 py-3 text-center text-xs font-bold uppercase text-white transition hover:bg-colorSecudario font-sans rounded-lg"
                        style={{ textDecoration: "none" }}
                      >
                        Leer más
                      </a>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>
        </section>
      </section>

      <Footer />
    </>
  );
};

export default Software;
