import { GiPlantsAndAnimals } from "react-icons/gi";
import { BsFillCpuFill } from "react-icons/bs";
import { AiFillMedicineBox } from "react-icons/ai";

export const featuresData = [
  {
    color: "blue",
    title: "Biología computacional",
    icon: GiPlantsAndAnimals,
    description: "Descripción",
  },
  {
    color: "red",
    title: "Computación de alto desempeño",
    icon: BsFillCpuFill,
    description: "Descripción",
  },
  {
    color: "teal",
    title: "Patología Computacional",
    icon: AiFillMedicineBox,
    description: "Descripción",
  },
];

export default featuresData;
