import React from "react";
import New from "../components/noticias";
import { Typography } from "@material-tailwind/react";
function Noticias() {
  return (
    <div>
      <br />
      <Typography
        variant="h3"
        className="font-bold font-sans"
        color="white-gray"
      >
        Noticias
      </Typography>
      <New showPaginator={true} noticiasPerPages={5} />
    </div>
  );
}

export default Noticias;
