import React from "react";
import { AiFillYoutube } from "react-icons/ai";
import { SiGmail } from "react-icons/si";
const year = new Date().getFullYear();
export default function Footer() {
  return (
    <footer class="text-blue-50 shadow-md bg-dark border-t border-colorPrincipal border-t-blur-2">
      <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col ">
        <div className="flex text-center p-3 font-sans ">
          Automatic Data-driven Analytics laboratory <br></br> Universidad de
          los Llanos
        </div>
        <p class="text-sm text-blue-50 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2018 - {year} Copyright:
          <a
            className=" font-sans"
            href="https://adalab.unillanos.edu.co/"
            style={{ textDecoration: "none" }}
          >
            {"   "}
            AdaLab
          </a>
        </p>
        <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a class="text-gray-500" href="/contact" target="_blank">
            <svg
              fill="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-12 h-14"
              viewBox="0 0 24 24"
            >
              <SiGmail />
            </svg>
          </a>

          <a
            class="ml-3 text-gray-500 hover:text-red-600"
            href="https://www.youtube.com/@automaticdata-drivenanalyt573"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-16 h-16"
              viewBox="0 0 24 24"
            >
              <AiFillYoutube />
            </svg>
          </a>
        </span>
      </div>
    </footer>
  );
}
