import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";
import { API_URL } from "../App.js";

function StatsCounter(props) {
  const [value, setValue] = useState(props.start);
  const [isVisible, setIsVisible] = useState(false);
  const [data, setData] = useState(null);
  const targetRef = useRef(null);
  const intersectionObserver = useRef(null);

  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(
      handleIntersection,
      {
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    if (targetRef.current) {
      intersectionObserver.current.observe(targetRef.current);
    }

    fetch(API_URL + "statistics")
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    return () => {
      if (intersectionObserver.current) {
        intersectionObserver.current.disconnect();
      }
      clearInterval(interval);
    };
  }, []);

  const handleIntersection = (entries) => {
    const isVisible = entries[0].isIntersecting;
    if (isVisible) {
      setIsVisible(true);
      startCounting();
    }
  };

  let interval;
  const startCounting = () => {
    interval = setInterval(() => {
      if (value < props.end) {
        setValue((prevValue) => prevValue + 1);
      } else {
        clearInterval(interval);
      }
    }, props.duration);
  };
  return (
    <div className="colorPrincipal mt-4" ref={targetRef}>
      {isVisible && (
        <div>
          <div className="bg-colorPrincipal pt-4 pb-4 ">
            <div className="text-white ">
              <div className="row">
                <div className="text-center col-lg-3 col-md-6 col-6 mb-30">
                  <h1 className="font-bold font-sans">
                    <CountUp
                      start={0}
                      end={data?.integrantes_count || 0}
                      duration={5}
                    />
                    +
                  </h1>
                  <h4 className="font-sans tracking-widest">Integrantes</h4>
                </div>
                <div className="single-fact text-center col-lg-3 col-md-6 col-6 mb-30">
                  <h1 className="font-bold font-sans">
                    <CountUp
                      start={0}
                      end={data?.publicacion_count || 0}
                      duration={5}
                    />
                    +
                  </h1>
                  <h4 className="font-sans tracking-widest">Publicaciones </h4>
                </div>

                <div className="single-fact text-center col-lg-3 col-md-6 col-6 mb-30 ">
                  <h1 className="font-bold font-sans">
                    <CountUp
                      start={0}
                      end={data?.software_count || 0}
                      duration={5}
                    />
                    +
                  </h1>
                  <h4 className="font-sans tracking-widest"> Software </h4>
                </div>

                <div className="single-fact text-center col-lg-3 col-md-6 col-6 mb-30 ">
                  <h1 className="font-bold font-sans">
                    <CountUp
                      start={0}
                      end={data?.proyecto_count || 0}
                      duration={5}
                    />
                    +
                  </h1>
                  <h4 className="font-sans  tracking-widest">Proyectos</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StatsCounter;
